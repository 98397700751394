import { createAsyncThunk } from '@reduxjs/toolkit';
import { serialize } from 'object-to-formdata';
import queryString from 'query-string';
import apiKey from '../../../utils/axiosInstance';
export const getTreatments = createAsyncThunk('treatment/getTreatments', async ({ limit, page }, ThunkApi) => {
    try {
        const query = queryString.stringify({ limit, page });
        const response = await apiKey.get(`/traitements?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getSearchedTreatments = createAsyncThunk('treatment/getSearchedTreatments', async ({ search, limit, page }, ThunkApi) => {
    try {
        const query = queryString.stringify(!search ? { limit, page } : { search, limit, page });
        const response = await apiKey.get(`/traitements/filter?${query}`);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const updateTreatment = createAsyncThunk('treatment/updateTreatment', async ({ params, id }, ThunkApi) => {
    try {
        const response = await apiKey.put(`/traitements/${id}`, params);
        const data = response.data;
        return data;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const getTreatmentById = createAsyncThunk('treatment/getTreatmentById', async ({ id }, ThunkApi) => {
    try {
        const response = await apiKey.get(`/traitements/${id}`);
        return response;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
export const exportPDF = createAsyncThunk('treatment/exportPDF', async ({ params }, ThunkApi) => {
    try {
        const response = await apiKey.post('/pdf', serialize({ ...params }), {
            headers: {
                'Content-Type': 'application/pdf',
            },
            responseType: 'blob',
        });
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(pdfBlob);
        downloadLink.download = 'output.pdf';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
        document.body.removeChild(downloadLink);
        return response;
    }
    catch (error) {
        return ThunkApi.rejectWithValue(error);
    }
});
